// Pages.scss - Styles for the Pages component

.body-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  &.mobile {
    // Mobile-specific styles
  }
  
  &.desktop {
    // Desktop-specific styles
  }
}

.main-header {
  text-align: center;
  padding: 10px;
  background-color: #0c213a;
  
  .logo-client {
    width: 100px;
  }
}

.content {
  flex: 1;
}

.mobile-content-container {
  // Mobile-specific content container styles
}

.main-footer {
  margin-top: auto;
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    
    .credit {
      margin-top: 1rem;
      text-align: center;
      
      a {
        color: inherit;
        text-decoration: none;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
} 